export default [

    {
        path: "/hotels",
        text: "hotels"
    },
    {
        path: "/flights",
        text: "flights"
    },
    {
        path: "/cars",
        text: "rental cars"
    }
]